.awsmob-wizard {
  padding-top: 20px;
}

.awsmob-wizard-navigation {
  color: #aab7b8;
}

.awsmob-wizard-navigation .active {
  font-weight: 700;
  color: #545b64;
}

.awsmob-wizard-navigation .future {
  pointer-events: none;
}

.awsmob-wizard-navigation .past {
  color: #007dbc;
  cursor: pointer;
}

.awsmob-wizard-navigation > div:not(:last-child) {
  border-bottom: 1px solid #d5dbdb;
}

.awsmob-wizard-navigation > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 1199px) {
  .awsmob-wizard-navigation {
    display: none
  }
}
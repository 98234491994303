.awsui-util-container .wrap-wizard-content{
    min-height: 200px;
}

.awsmob-wizard-navigation > div {
    padding: 10px 0 0;
}

.awsmob-wizard-navigation > div:hover {
    background: #fafafa;
    cursor: pointer;
}

.awsmob-wizard-navigation .active,
.awsmob-wizard-navigation .past,
.awsmob-wizard-navigation .future
{
    padding:0 10px 20px;
    
    border-bottom: 1px solid #687078;
}

.awsmob-wizard-navigation small {
    padding: 0 10px;
}

.awsmob-wizard-navigation .past {
    font-weight: bold;
    color: #0073bb;
}
.awsmob-wizard-navigation .active{
    border-bottom: 2px solid #687078;
    font-weight: bold;
}
.awsmob-wizard-navigation .future{
    color: #687078;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ClusterView */
awsui-app-layout.cluster main.awsui-app-layout__content {
  width: 100%;
  padding: 0;
}
awsui-app-layout.cluster .animated-wrapper {
  padding: 2rem 4rem;
}
awsui-app-layout.cluster .awsui-app-layout__breadcrumbs {
  position: absolute;
  top: 2rem;
  left: 4rem;
  z-index: 1;
}

.App {
  text-align: center;
}

html { 
  background-color: #F2F3F3;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.awsmob-wizard {
  padding-top: 20px;
}

.awsmob-wizard-navigation {
  color: #aab7b8;
}

.awsmob-wizard-navigation .active {
  font-weight: 700;
  color: #545b64;
}

.awsmob-wizard-navigation .future {
  pointer-events: none;
}

.awsmob-wizard-navigation .past {
  color: #007dbc;
  cursor: pointer;
}

.awsmob-wizard-navigation > div:not(:last-child) {
  border-bottom: 1px solid #d5dbdb;
}

.awsmob-wizard-navigation > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 1199px) {
  .awsmob-wizard-navigation {
    display: none
  }
}
.awsui-util-container .wrap-wizard-content{
    min-height: 200px;
}

.awsmob-wizard-navigation > div {
    padding: 10px 0 0;
}

.awsmob-wizard-navigation > div:hover {
    background: #fafafa;
    cursor: pointer;
}

.awsmob-wizard-navigation .active,
.awsmob-wizard-navigation .past,
.awsmob-wizard-navigation .future
{
    padding:0 10px 20px;
    
    border-bottom: 1px solid #687078;
}

.awsmob-wizard-navigation small {
    padding: 0 10px;
}

.awsmob-wizard-navigation .past {
    font-weight: bold;
    color: #0073bb;
}
.awsmob-wizard-navigation .active{
    border-bottom: 2px solid #687078;
    font-weight: bold;
}
.awsmob-wizard-navigation .future{
    color: #687078;
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ClusterView */
awsui-app-layout.cluster main.awsui-app-layout__content {
  width: 100%;
  padding: 0;
}
awsui-app-layout.cluster .animated-wrapper {
  padding: 2rem 4rem;
}
awsui-app-layout.cluster .awsui-app-layout__breadcrumbs {
  position: absolute;
  top: 2rem;
  left: 4rem;
  z-index: 1;
}
